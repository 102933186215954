import { GET_ORDER_LIST, SET_ORDERS_ERROR } from "../store/types";
import ApiService from "../common/api.service";
import { SET_WALLET_CASH_REQUEST_ERROR } from "../store/modules/walletCashRequest";
import handleReject from "../common/handleReject";

export default {
  methods: {
    getOrderList(page = 1, start_time = '', end_time = '', busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_ORDER_LIST, {page, start_time, end_time})
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    getOrderCounterList(start_time = '', end_time = '', busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/manager/order/counter-list`, `?start_time=${start_time}&end_time=${end_time}`)
        .then(response=>{
          resolve(response);
          this.$store.commit(SET_ORDERS_ERROR, null );
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_ORDERS_ERROR);
          busy && this.hideBusy();
          reject(response);
        })
      })
    }
  }
}
